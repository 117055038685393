<template>
  <div>
    <header class="fr-header mt-0">
      <div class="fr-header__body">
        <div class="fr-container">
          <div class="fr-header__body-row">
            <div class="fr-header__brand">
              <router-link
                class="logo-wrapper"
                :to="{ name: isUserLogIn ? 'Tableau de bord' : 'Accueil' }"
              >
                <div class="fr-header__logo">
                  <div
                    class="fr-logo no-box-shadow"
                    id="hea_01"
                    title="république française"
                  >
                    <span class="fr-logo__title"
                      >république <br />française</span
                    >
                    <span class="sr-only">Liberté égalité fraternité</span>
                  </div>
                </div>

                <div class="fr-header__operator header-navbar">
                  <div class="fr-service">
                    <img
                      v-if="deuil"
                      src="../assets/img/logo-portailprogouv-deuil.svg"
                      width="95"
                      height="35"
                      alt="texte avec écrit Portailpro.gouv en noir"
                    />
                    <img
                      v-else
                      src="../assets/img/logo-portailprogouv.svg"
                      width="95"
                      height="35"
                      alt="texte avec écrit Portailpro.gouv en rouge et bleue"
                    />
                  </div>
                </div>
              </router-link>
              <div class="fr-header__navbar">
                <button
                  data-v-558e010e=""
                  id="hea_03"
                  title="Rechercher"
                  class="fr-btn rechercher fr-icon-search-line"
                  aria-controls="modal-search"
                  aria-haspopup="menu"
                  @click="toggleMenuRecherche()"
                ></button>
                <button
                  class="fr-icon-menu-fill fr-btn"
                  data-fr-opened="false"
                  aria-controls="modal-870"
                  aria-haspopup="menu"
                  title="Ouvrir le menu"
                  id="fr-btn-menu-mobile"
                  @click="toggleMenuFill()"
                >
                  <span class="sr-only">Ouvrir le menu</span>
                </button>
                <div id="block-headeractions" v-if="displayHeaderactions">
                  <div class="header__actions">
                    <button
                      id="printButton2"
                      class="fr-icon-printer-line bouton-bleu"
                      title="Imprimer le contenu de la page"
                      @click="print()"
                    ></button>
                    <span class="separator"></span>
                    <button
                      id="increaseDecreaseButton"
                      class="ri-font-size bouton-bleu"
                      :title="
                        textIncrease ? 'Agrandir le texte' : 'Réduire le texte'
                      "
                      @click="increaseDecrease()"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
            <div class="fr-header__tools" v-if="isNonAuthentifie">
              <div class="fr-header__tools-links">
                <!-- PCR-4960 RGAA Navigation (12) > ordre tabulation (12.11)-->
                <!-- Transformation & dupplication en tag <a> pour avoir le comportement normal du focus sans avoir recours aux tabindex-->
                <ul class="fr-links-group remove__padding">
                  <li>
                    <!-- PCR-4960 RGAA Navigation (12) > ordre tabulation (12.11)-->
                    <!-- Transformation de <div> en bouton pour récupérer le focus suite à un tab-->
                    <a
                      id="hea_10"
                      href="/ms/redirectiam"
                      class="fr-link fr-fi-user-line votre-compte"
                      @click="actionUser()"
                      :title="isUserLogIn ? fullname : 'Accéder à votre compte'"
                    >
                      {{ isUserLogIn ? fullname : 'Votre compte' }}
                    </a>
                  </li>
                </ul>
              </div>
              <!-- PCR-5413 Masquer la barre de recherche pour la V1-->
              <div class="fr-container margin-top-15">
                <div class="searchbar-column">
                  <div class="searchbar-row">
                    <div
                      class="fr-search-bar margin-right-10"
                      id="header-search"
                    >
                      <label class="fr-label" for="header-search-input"
                        >Barre de recherche</label
                      >
                      <input
                        v-model="requete"
                        ref="search"
                        class="fr-input"
                        placeholder="Rechercher"
                        type="search"
                        id="header-search-input"
                        name="header-search-input"
                        size="60"
                        maxlength="128"
                        v-on:keyup.enter="chercher($event)"
                        v-on:keyup="keyup($event)"
                        @input="onInputChangeCheck"
                        @click="toggleRecherche()"
                        @blur="onBlur"
                        @focusout="scheduleHideRecentSuggestion"
                      />
                      <button
                        class="fr-btn rechercher"
                        id="hea_03"
                        title="Lancer la recherche"
                        @click="chercher($event)"
                      ></button>
                      <!-- Suggestions dropdown -->
                      <div
                        v-if="isTyping && suggestions.length > 0"
                        class="suggestions-dropdown"
                        id="suggestion"
                      >
                        <div
                          class="suggestion"
                          v-for="(suggestion, index) in suggestions"
                          :key="index"
                          @mousedown="selectSuggestion(suggestion)"
                        >
                          {{ suggestion }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="searchbar-row">
                    <div
                      v-show="!isTyping && recents.length > 0"
                      id="recent-header"
                      class="fr-search-bar rectangle margin-right-10"
                    >
                      <p
                        class="recent-select"
                        v-for="(item, index) in recents"
                        :key="index"
                        @click="chercher($event)"
                        @mouseover="mouseOver(index, item)"
                        @mouseout="mouseOut()"
                      >
                        <a
                          class="cds-no-border"
                          href="cds/reques"
                          :title="'recherche récente : ' + item"
                        >
                          <span><i class="ri-time-line recent-icon"></i></span
                          ><span class="recent-text">{{ item }} </span>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="fr-header__tools" v-if="!isNonAuthentifie">
              <div class="fr-header__tools-links">
                <!-- PCR-4960 RGAA Navigation (12) > ordre tabulation (12.11)-->
                <!-- Transformation & dupplication en tag <a> pour avoir le comportement normal du focus sans avoir recours aux tabindex-->
                <ul class="fr-links-group">
                  <li
                    v-if="!this.hideMenu()"
                    class="messagerie-mobile"
                    @click="tagHeader('messagerie')"
                  >
                    <router-link
                      v-if="messagerie"
                      class="fr-link fr-link--icon-left color-pr messagerie"
                      v-infobulle
                      data-toggle="popover"
                      :data-content="''"
                      data-trigger="focus"
                      target="_self"
                      :to="{ name: 'Messagerie' }"
                    >
                      <span id="mes_06">{{
                        indicateurs.nbDemandesNonlus
                      }}</span>
                      <i class="ri-mail-line"></i>
                      Messagerie
                    </router-link>
                    <Info
                      v-if="!messagerie"
                      href="#"
                      id="hea_9"
                      :data="'Service non accessible, vous ne disposez pas d\'habilitation'"
                      cssClass="ri-mail-line fr-link fr-link--icon-left color-pr messagerie messagerie-sans-indicateurs no-box-shadow"
                      dataContent="Messagerie"
                    />
                  </li>
                  <li class="Line-2 messagerie-mobile"></li>
                  <li class="messagerie-mobile">
                    <!-- PCR-4960 RGAA Navigation (12) > ordre tabulation (12.11)-->
                    <!-- Transformation de <div> en bouton pour récupérer le focus suite à un tab-->
                    <button
                      id="hea_10"
                      class="fr-link fr-fi-user-line"
                      @click="actionUser()"
                      :title="
                        isUserLogIn
                          ? fullname
                          : 'Cliquer ici pour vous connecter'
                      "
                    >
                      {{ isUserLogIn ? fullname : 'Se connecter' }}
                    </button>
                    <GestionnaireCompteModal
                      v-show="isShowModal"
                      @close="closeModal()"
                      :user="user"
                      :isLogged="isUserLogIn"
                      :isShowModal="isShowModal"
                      @update-showModal="updateModal"
                    />
                  </li>
                </ul>

                <!-- PCR-4942 -->
                <div class="sr-only-display">
                  <header class="modal-header">
                    <slot name="header">
                      <div class="userIcon">
                        <span class="fr-fi-user-line" />
                      </div>
                      <div class="userInfo">
                        <div class="userName">{{ user.name }}</div>
                        <div class="userEmail">{{ user.email }}</div>
                      </div>
                    </slot>
                  </header>
                  <section class="modal-body">
                    <slot name="body">
                      <ul class="noDot" v-if="isUserLogIn">
                        <li>
                          <router-link
                            :to="{ name: 'Gestion des rattachements' }"
                            class="fr-link fr-link--icon-left fr-name-tag link-color"
                            target="_self"
                            title="Gérer mes rattachements"
                            ><i class="ri-arrow-right-line" />
                            Gérer mes rattachements
                          </router-link>
                        </li>
                        <a
                          class="fr-link fr-link--icon-left fr-name-tag link-color gmc"
                          title="Gérer mon compte"
                        >
                          Gérer mon compte
                        </a>
                        <!-- PCR-4960 RGAA Navigation (12) > ordre tabulation (12.11)-->
                        <!-- Transformation des tag <a> en boutons car le href est calculé dynamiquement avec la méthode checkSiren() et l'absence du href le rend non focusable avec tab-->

                        <ul class="noDot">
                          <li>
                            <router-link
                              :to="routeGestionCompte"
                              class="fr-link fr-link--icon-left fr-name-tag link-color"
                              target="_self"
                              title="Gérer les rôles"
                            >
                              <i class="ri-arrow-right-line" />
                              Gérer les rôles
                            </router-link>
                          </li>
                          <li>
                            <button
                              @click="checkSiren('/habilitations')"
                              class="fr-link fr-link--icon-left fr-name-tag link-color"
                              target="_self"
                              title="Gérer mes habilitations"
                              aria-label="Gérer mes habilitations"
                            >
                              <i class="ri-arrow-right-line" />
                              Gérer mes habilitations
                            </button>
                          </li>
                          <li>
                            <a
                              class="fr-link fr-link--icon-left fr-name-tag link-color"
                              target="_self"
                              :href="gererCompteHref"
                              title="Gérer mes informations personnelles"
                              ><i class="ri-arrow-right-line" />
                              Gérer mes informations personnelles
                            </a>
                          </li>
                        </ul>
                      </ul>
                      <div v-else />
                    </slot>
                  </section>
                  <footer class="modal-footer">
                    <slot name="footer">
                      <button
                        type="button"
                        class="btn btn-dec deconnexion"
                        @click="userAction()"
                        title="Cliquer ici pour vous déconnecter"
                      >
                        <span class="ri-shut-down-line icon-dec" />
                        <span class="button-label">{{ boutonLabel }}</span>
                      </button>
                    </slot>
                  </footer>
                </div>
              </div>
              <!-- PCR-5413 Masquer la barre de recherche pour la V1-->
              <div class="fr-container margin-top-15">
                <div class="searchbar-column">
                  <div class="searchbar-row">
                    <div
                      class="fr-search-bar margin-right-10"
                      id="header-search"
                    >
                      <label class="fr-label" for="header-search-input"
                        >Barre de recherche</label
                      >
                      <!-- champ fictif invisible et inactif RGAA pour gérer le focus suite à une navigation via lien d'évitement-->
                      <input
                        ref="searchFocus"
                        type="text"
                        class="sr-only"
                        aria-hidden="true"
                        :tabindex="tabIndex"
                        @blur="handleBlur"
                      />
                      <input
                        v-model="requete"
                        ref="search"
                        class="fr-input"
                        placeholder="Rechercher"
                        type="search"
                        id="header-search-input"
                        name="header-search-input"
                        size="60"
                        maxlength="128"
                        v-on:keyup.enter="chercher($event)"
                        @input="onInputChangeCheck"
                        @click="toggleRecherche()"
                        @focusout="scheduleHideRecentSuggestion"
                      />
                      <button
                        class="fr-btn rechercher"
                        id="hea_03"
                        title="Rechercher"
                        @click="chercher($event)"
                      ></button>
                      <!-- Suggestions dropdown -->
                      <div
                        v-if="isTyping && suggestions.length > 0"
                        class="suggestions-dropdown"
                        id="suggestion"
                      >
                        <div
                          class="suggestion"
                          v-for="(suggestion, index) in suggestions"
                          :key="index"
                          @mousedown="selectSuggestion(suggestion)"
                        >
                          {{ suggestion }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="searchbar-row">
                  <div
                    v-if="!isTyping && recents.length > 0"
                    id="recent-header"
                    class="fr-search-bar rectangle margin-right-10"
                  >
                    <p
                      class="recent-select"
                      v-for="(item, index) in recents"
                      :key="index"
                      @click="chercher($event)"
                      @mouseover="mouseOver(index, item)"
                      @mouseout="mouseOut()"
                    >
                      <a
                        class="cds-no-border"
                        href="cds/reques"
                        :title="'recherche récente : ' + item"
                      >
                        <span><i class="ri-time-line recent-icon"></i></span
                        ><span class="recent-text">{{ item }} </span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="fr-header__menu fr-modal margin-bottom-10"
        id="modal-search"
        aria-labelledby="hea_03"
      >
        <div class="fr-container">
          <button
            @click="toggleMenuRecherche()"
            class="fr-link--close fr-link"
            aria-controls="modal-search"
          >
            Fermer
          </button>
          <div class="fr-search-bar" id="header-search-mobile" role="search">
            <label class="fr-label" for="search-784-input"> Recherche </label>
            <input
              v-model="requete"
              class="fr-input"
              placeholder="Rechercher"
              type="search"
              id="search-784-input"
              name="search-784-input"
            />
            <button
              class="fr-btn"
              title="Lancer la recherche"
              @click="chercher($event)"
            >
              Rechercher
            </button>
          </div>
        </div>
      </div>

      <div
        class="fr-header__menu fr-modal margin-bottom-10"
        id="modal-870"
        aria-labelledby="fr-btn-menu-mobile"
      >
        <div ref="menu" class="fr-container">
          <button class="fr-link--close fr-link" aria-controls="modal-870">
            Fermer
          </button>
          <div id="block-headeractions" v-if="!displayHeaderactions">
            <div class="header__actions">
              <button
                id="printButton"
                class="fr-icon-printer-line bouton-bleu"
                title="Imprimer le contenu de la page"
                v-on:click="print()"
              ></button>
              <span class="separator"></span>
              <button
                id="increaseDecreaseButton"
                class="ri-font-size bouton-bleu"
                :title="textIncrease ? 'Agrandir le texte' : 'Réduire le texte'"
                v-on:click="increaseDecrease()"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </header>

    <div ref="content" id="page">
      <div class="fr-container fr-mt-8w fr-mb-6w">
        <div class="fr-grid-row flex-column-reverse flex-md-row">
          <div class="fr-col-12 fr-col-md-4 fr-mt-2w content-col-text">
            <h1 class="er-title">Erreur 404</h1>
            <h2 class="er-under-title">Page introuvable</h2>
            <p class="er-p fr-mt-10v">
              Vérifiez que le lien de la page est correct.<br />
              La page peut avoir été déplacée, supprimée ou elle est peut-être
              inexistante.
            </p>
            <div class="fr-mt-10v">
              <a
                href="#"
                class="fr-btn fr-btn--lg fr-btn--secondary precedent"
                @click="precedent($event)"
                title="Page précédente"
              >
                <i class="ri-arrow-left-line"></i>Page précédente
              </a>
            </div>
            <div class="fr-mt-5v">
              <router-link
                :to="{ name: isUserLogIn ? 'Tableau de bord' : 'Accueil' }"
                class="fr-btn fr-btn--lg accueil"
                title="Page d'acceuil"
              >
                <i class="ri-home-2-line"></i>Page d’accueil
              </router-link>
            </div>
          </div>
          <div class="fr-col-12 fr-col-md-7 content-col-image">
            <img
              class="error-image"
              src="../assets/img/error.svg"
              alt="image représentant un écran avec écrit erreur 404 dedans"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GestionnaireCompteModal from '../shared/GestionnaireCompteModal';
import Info from '../shared/Info';
import UtilsMixin from '../mixins/utils-mixin';
import {
  sendTag,
  isFeatureActive,
  isMobileDevice
} from '../utils/methodsUtils';
import { isTokenValide } from '../utils/authUtils';
import { deuil } from '../services/AdministrationService';
import { getFifo, removeItem } from '../utils/cdsUtils';

import { EVENT_BUS_ERGO_INCREASE_DECREASE_FONT } from '../constantes';
import { mapGetters } from 'vuex';
import autocompleteMixin from '../mixins/autocompleteMixin';

export default {
  name: 'Error404',
  mixins: [UtilsMixin, autocompleteMixin],
  components: {
    GestionnaireCompteModal,
    Info
  },
  data() {
    return {
      isAuth: false,
      isShowModal: false,
      isUserLogIn: false,
      requete: '',
      tabIndex: '-1',
      deuil: false,
      recents: [],
      recentIndex: -1,
      textIncrease: true,
      path: '',
      window: {
        width: window.innerWidth,
        height: 0
      }
    };
  },
  updated() {
    this.authController();
  },
  async mounted() {
    this.recents = getFifo();
    var x = document.getElementById('recent-header');
    if (x) {
      x.style.display = 'none';
    }
    this.isAuth = isTokenValide(false);

    this.authController();
    await deuil().then((response) => {
      if (response && response.data && response.data.resultat) {
        document.documentElement.setAttribute('data-fr-mourning', '');
        this.deuil = true;
      }
    });
  },
  computed: {
    user() {
      return {
        name: this.fullname,
        email: this.email,
        isAGE: this.isAge
      };
    },
    ...mapGetters([
      'fullname',
      'email',
      'isAge',
      'codeAffichages',
      'connexionCourante',
      'indicateurs',
      'etablissements',
      'siren'
    ]),
    //pour RGAA
    boutonLabel() {
      return this.isUserLogIn ? 'Déconnexion' : 'Connexion';
    },
    gererCompteHref() {
      // TODO: ajouter les variable des environnement
      return this.isLogged
        ? `${process.env.VUE_APP_DNS_WSO2}/user-portal/`
        : '';
    },
    routeGestionCompte() {
      return this.isAge
        ? "{'Détail de mes actions'}"
        : "{name: 'Gestion des rôles'}";
    },
    messagerie() {
      return this.accesMessagerie(this.codeAffichages);
    },

    isNonAuthentifie() {
      return this.$route.meta.withoutAuth || !(this.email && this.email != '');
    },
    displayHeaderactions() {
      return this.window.width <= 991 && !isMobileDevice();
    }
  },
  methods: {
    precedent(event) {
      if (event) {
        event.preventDefault();
      }
      this.$router.go(-1);
    },
    onInputChangeCheck() {
      if (isFeatureActive('PCR2-9132')) {
        this.isAuth = isTokenValide(false);
        this.onInputChange();
      }
    },
    selectSuggestion(suggestion) {
      this.suggestionClicked = true;
      this.requete = suggestion;
      this.suggestions = [];
      this.chercher();
    },
    handleBlur() {
      this.tabIndex = '-1';
    },
    updateModal(showModal) {
      this.isShowModal = showModal;
    },
    showModal() {
      this.isShowModal = true;
    },
    closeModal() {
      this.isShowModal = false;
    },
    actionUser() {
      return this.isUserLogIn ? this.showModal() : this.$auth.login();
    },
    authController() {
      const isUserLog = this.fullname.length > 0;
      this.isUserLogIn = isUserLog;
    },
    shouldShowEtablissement() {
      if (this.siren) {
        if (this.etablissements) {
          return (
            this.etablissements.filter(
              ({ categorie }) => categorie === 'RG' || 'TIAC' || 'TIPL'
            ).length >= 2 && this.$route.meta.showRSsiret
          );
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    shouldShowFilArianne() {
      if (
        this.$route.name === 'Gestion des rattachements' &&
        !this.connexionCourante
      ) {
        return false;
      } else if (
        this.$route.name === 'Gestion des rattachements' &&
        this.connexionCourante
      ) {
        return true;
      }

      if (this.$route.name === 'Tableau de bord') {
        return false;
      } else if (this.$route.name === "Sélection de l'entreprise") {
        return false;
      } else if (this.$route.name === "Sélection de l'établissement") {
        return false;
      } else if (this.$route.name === 'Service non accessible') {
        return false;
      } else {
        return true;
      }
    },
    hideMenu() {
      if (
        ["Sélection de l'entreprise", "Sélection de l'établissement"].includes(
          this.$route.name
        )
      ) {
        return true;
      } else if (this.$route.name === 'Service non accessible') {
        return false;
      } else {
        return false;
      }
    },
    shouldShowNavBar() {
      if (this.hideMenu()) {
        return false;
      }

      if (
        this.$route.name !== 'Gestion des rattachements' ||
        this.connexionCourante
      ) {
        return true;
      } else {
        return false;
      }
    },
    toggleMenuRecherche() {
      let bouton = document.getElementById('hea_03');
      let modal = document.getElementById('modal-search');
      if (bouton !== null) {
        if (bouton.getAttribute('data-fr-opened') === 'true') {
          bouton.setAttribute('data-fr-opened', 'false');
          modal.classList.remove('fr-modal--opened');
        } else {
          bouton.setAttribute('data-fr-opened', 'true');
          modal.classList.add('fr-modal--opened');
        }
      }
    },
    /**Dérouler le menu */
    toggleMenuFill() {
      let bouton = document.getElementById('fr-btn-menu-mobile');
      if (bouton !== null) {
        if (bouton.getAttribute('data-fr-opened') === 'true') {
          bouton.setAttribute('data-fr-opened', 'false');
        } else {
          bouton.setAttribute('data-fr-opened', 'true');
        }
      }
    },
    hideMMenu() {
      this.toggleMenuFill();
    },
    tagHeader(tagName) {
      sendTag(tagName, 'header_authentifie', '', '');
    },
    chercher(e) {
      if (e) {
        e.preventDefault();
      }

      if (isFeatureActive('PCR2-9132')) {
        let routeData = this.$router.resolve({
          name: 'Outil de recherche',
          query: {
            searchText: encodeURI(this.requete)
          }
        });

        window.open(routeData.href, '_self', 'noopener');
      } else {
        const url =
          process.env.VUE_APP_DNS_PCR_SNA +
          'recherche?searchText=' +
          this.requete;
        window.open(url, 'drupal');
      }
    },
    toggleRecherche() {
      if (isFeatureActive('PCR2-9132')) {
        var x = document.getElementById('recent-header');
        if (x) {
          if (x.style.display === '') {
            x.style.display = 'none'; // seulement initialiser si aucune valeur n'est définie
          }
          if (x.style.display === 'none' && this.recents.length) {
            x.style.display = 'block';
          } else {
            x.style.display = 'none';
          }
        }
      }
    },
    mouseOver(index, item) {
      this.recentIndex = index;
      this.requete = item;
    },
    mouseOut() {
      this.recentIndex = -1;
    },
    keyup(e) {
      // touche Suppr
      if (e.which === 46 && this.recentIndex !== -1) {
        this.recents = removeItem(this.recentIndex);
        if (!this.recents.length) {
          this.hideRecent();
        }
      }
    },
    print2() {
      document.getElementById('printButton2').blur();
      window.print();
    },
    print() {
      document.getElementById('printButton').blur();
      window.print();
    },
    increaseDecrease() {
      document.getElementById('increaseDecreaseButton').blur();
      this.emitter.emit(EVENT_BUS_ERGO_INCREASE_DECREASE_FONT, {
        textIncrease: this.textIncrease
      });
      this.textIncrease = !this.textIncrease;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    hideRecent() {
      clearTimeout(this.hideRecentTimeout);
      var x = document.getElementById('recent-header');
      if (x) {
        x.style.display = 'none';
      }
    },
    hideSuggestion() {
      clearTimeout(this.hideSuggestionTimeout);
      var x = document.getElementById('suggestion');
      if (x) {
        x.style.display = 'none';
      }
    },
    scheduleHideRecentSuggestion() {
      this.hideRecentTimeout = setTimeout(this.hideRecent, 200);
      this.hideSuggestionTimeout = setTimeout(this.hideSuggestion, 200);
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style lang="scss" scoped>
.gcb-right .gcb-startCobrowse,
.gcb-startBtnsContainer .gcb-startCobrowse .gcb-startBtnsContainer {
  display: none !important;
}

.logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.fr-header__logo {
  margin-right: 0 !important;
  padding-right: 9px;
}

.fr-header__operator {
  padding-top: 0.5rem;
}

.content-col-text,
.content-col-image {
  text-align: center;
}

.content-col-image {
  padding: 0 50px;
}

.er-title {
  color: $base-color;
  font-family: Marianne;
  font-size: 40px;
  font-weight: bold;
}
.er-under-title {
  color: $font-color-base;
  font-family: Marianne;
  font-size: 32px;
  font-weight: bold;
}
.er-p {
  margin-left: 2.25rem;
  margin-right: 2.25rem;
  font-family: Marianne;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
}

.error-image {
  width: 100%;
  max-width: 423px;
}

@media (min-width: 768px) {
  .content-col-text {
    text-align: left;
  }

  .content-col-image {
    padding: 0;
  }

  .er-p {
    margin-left: 0;
    margin-right: 0;
  }
}

.fr-btn:not(.fr-btn--secondary) {
  background-color: $base-color !important;
}

.fr-btn:not(.fr-btn--secondary):hover {
  background-color: #1212ff !important;
}

.fr-search-bar {
  position: relative;
  max-width: 25rem;

  @media (max-width: 992px) {
    max-width: inherit;
  }
}

.fr-search-bar .fr-input {
  box-shadow: inset 0 -2px 0 0 $base-color;
}
.fr-name-tag {
  color: $base-color;
}

.messagerie {
  position: relative;
  display: inline-flex;
  align-items: center;
  span {
    background: #e1000f;
    border-radius: 50%;
    width: 17px;
    height: 17px;
    color: white;
    font-size: 13px;
    margin-top: 0px;
    line-height: 15px;
    text-align: center;
    position: relative;
    z-index: 2;
    left: 4px;
    top: -7px;
  }
  i {
    font-size: 20px;
    position: relative;
    right: 5px;
    top: 1px;
  }
}

.block-rs {
  background: rgb(221, 221, 221);
  background: linear-gradient(
    180deg,
    rgba(221, 221, 221, 1) 0%,
    rgba(255, 255, 255, 1) 35%
  );
  border-bottom: 1px solid #ddd;
  position: relative;
}

.Line-2 {
  width: 1px;
  height: 20px;
  margin: 5px 0 0 0px;
  border: solid 1px #ddd;
}

.remove__padding {
  padding-left: 0px;
}

//PCR-3611- ligne de séparation entre header et menu
.separation {
  width: 100%;
  height: 1px;
  background-color: #f0f0f0;
}

//PCR-3616- SNA_Logo_Marianne_mise_en_conformité
.fr-header__navbar .fr-service__title {
  display: inline-block;
}
.fr-logo {
  width: 94px;
  height: 78px;
}
.header-navbar {
  @media (max-width: 810px) {
    width: 60%;
    position: unset;
    margin-top: 0;
  }
}

.fr-skiplinks__list > li > .fr-link {
  border-radius: 0;
  box-shadow: none;
}
.header-body {
  @media (max-width: 810px) {
    padding-bottom: 1.5rem;
  }
}

//PCR-3609- SNA_Header_mise_en_conformité_Vue.js
@media (min-width: 768px) {
  .user {
    margin-right: -0.75rem;
  }
  .shortcuts {
    display: flex;
    margin: 0 0;
  }
  .fr-header__body {
    height: 116px !important;
  }
}

.fr-header__body {
  @media (max-width: 990px) {
    width: 100%;
  }
}
.styled-1 select:focus {
  box-shadow: 0 0 3pt 2pt #719ece;
}

a:focus {
  border-radius: 0;
}

.no-box-shadow {
  box-shadow: none;
}
a.messagerie,
#hea_10 {
  font-weight: inherit !important;
}

//RGAA
.sr-only-display {
  display: none;
}

.invisible {
  display: none;
}

.s-dim {
  height: 40px;
  width: 356px;
}

#menu-burger {
  background-color: transparent !important;
}
@media (max-width: 767px) {
  .messagerie-mobile {
    display: none;
  }
  .fr-header {
    height: 90px;
  }
}
#fr-btn-menu-mobile {
  background-color: transparent !important;
}

#hea_9,
a.messagerie {
  font-size: 1rem !important;
}
#hea_10 {
  font-size: 0.875rem;
  margin-left: 0;
}
#hea_10::before {
  width: 1rem;
  height: 1rem;
}
@media only screen and (max-width: 991px) {
  div.fr-service {
    margin-bottom: -0.75rem;
  }

  .full-width {
    width: 100vw;
  }

  .votre-compte {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  #hea_03 {
    background-color: white !important;
  }
}
@media (min-width: 992px) {
  #header-search-mobile.fr-search-bar {
    display: none;
  }
}

.margin-top-15 {
  margin-top: 10px;
}

.margin-bottom-10 {
  margin-top: 10px;
}

.margin-right-10 {
  margin-right: -10px;
}

.fr-header__tools {
  @media (max-width: 990px) {
    display: none;
  }
}

.logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.fr-header__logo {
  margin-right: 0 !important;
}

.searchbar-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.searchbar-column {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.fr-icon-menu-fill {
  color: var(--text-default-grey);
}

.rectangle {
  width: 100%;
  z-index: 100;
  padding: 14px 14px 15px 16px;
  border-radius: 5px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.33),
    inset 0 0 2px 0 rgba(255, 255, 255, 0.5);
  border: solid 0.5px #aaa;
  background-color: #f6f6f6;
  display: none;
  text-align: left;
}

.fr-header__brand {
  align-self: flex-start;
  margin-top: 0.25rem;
}

.recent-select:hover {
  background-color: #f8f9fa;
}

.recent-text {
  margin-left: 10px;
  font-family: Marianne;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #434343;
}

#block-headeractions .header__actions {
  display: flex;
  justify-content: flex-end;
  margin: 0.6rem 0;
  button:hover {
    background-color: rgba(0, 0, 0, 0.05) !important;
  }
  button:active {
    background-color: #e0e0e0 !important;
  }
}

#block-headeractions .header__actions button {
  font-size: 20px;
  color: #000091;
  vertical-align: middle;
  border-style: none;
  border-radius: 50%;
  height: 32px;
  width: 32px;
}

#block-headeractions .header__actions .separator {
  font-size: 20px;
  margin: 0 0.25rem;
  vertical-align: middle;
  border-right: 1px solid #e7e7e7;
}

@media (min-width: 992px) {
  .fr-header__operator {
    padding-top: 0.5rem;
    padding-bottom: 0.75rem;
  }

  #header-search-mobile.fr-search-bar {
    display: none;
  }

  .content-col-image {
    padding-left: 250px;
  }

  #block-headeractions .header__actions .separator {
    margin: 0 0.5rem;
  }
}

.suggestions-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-width: 100%;
  z-index: 100;
  padding: 14px 14px 15px 16px;
  border-radius: 5px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.33),
    inset 0 0 2px 0 rgba(255, 255, 255, 0.5);
  border: solid 0.5px #aaa;
  background-color: #f6f6f6;
  text-align: left;
}

.suggestion {
  padding: 10px;
  cursor: pointer;
  font-family: Marianne;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #434343;
  width: 100%;
}

.suggestion:hover {
  background-color: #f8f9fa;
}
</style>
